import WebServiceRequest from '../Api/WebServiceRequest'

class OrderChangeStatusRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
    }
    setId(data){
        super.setTag('Orders/ChangeOrderStatus?orderId='+data.orderId+'&status='+data.status)
    }
}
class OrderCreateByAdmin extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Orders/CreateByAdmin')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}
class OrderCreateForUI extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setMethod("GET")
        super.setTag('Orders/GetAllForUI')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}
class OrderCreateRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Orders/Create')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}
class OrderCreateForAdminRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Orders/CreateForAdmin')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}
class OrderDeleteRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
    }
    setId(id){
        super.setTag('Orders/Delete?id='+id)
    }
}
class OrderGetAllRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setMethod("GET")
        super.setTag('Orders/GetAllForAdmin')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}
class OrderGetByUserIdRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Orders/GetByUserId')
    }
}
class OrderGetProductsForStudents extends WebServiceRequest {
    constructor (context) {
        super(context)
    }
    setParams(data){
        super.setTag('Orders/GetProductsForStudents?searchCommand='+data.searchCommand+'&pageNumber='+data.pageNumber+'&count='+data.count)
    }
}
class OrderGetRegisteredUserRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Orders/GetRegisteredUser')
    }
    setParams(data){
       super.setRequestParam(data)
    }
}
class OrderGetRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Orders/GetBy')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}

class OrderPaymentDoneByUserMilestone extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Orders/PaymentDoneByUserMilestone')
    }
    setParams(data){
        super.setFormData(data)
    }
}

class LiveStreamGetOrderLiveStreamPlayerLink extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('LiveStream/GetOrderLiveStreamPlayerLink')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}
export {
    OrderChangeStatusRequest,
    OrderCreateRequest,
    OrderCreateByAdmin,
    OrderDeleteRequest,
    OrderGetAllRequest,
    OrderGetByUserIdRequest,
    OrderGetProductsForStudents,
    OrderGetRegisteredUserRequest,
    OrderGetRequest,
    OrderCreateForAdminRequest,
    OrderCreateForUI,
    OrderPaymentDoneByUserMilestone,
    LiveStreamGetOrderLiveStreamPlayerLink
};
